/* eslint-disable no-unused-vars, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import * as types from './types';

const INITIAL_SITE = {
  loading: false,
  filters: [],
  fetchingFilters: [],
};

export default createReducer(INITIAL_SITE, (builder) => {
  builder
    .addCase(types.FETCH_FILTER, (state) => {
      state.loading = true;
      return state;
    })
    .addCase(types.FETCHING_FILTER, (state, action) => {
      const { siteId, isFetching } = action.payload;
      const fetchingFilter = state.fetchingFilters.find(
        (item) => item.siteId === siteId,
      );
      if (!fetchingFilter) {
        state.fetchingFilters = [
          ...state.fetchingFilters,
          { siteId, isFetching },
        ];
      } else {
        fetchingFilter.isFetching = isFetching;
      }
      return state;
    })
    .addCase(types.FETCH_FILTER_SUCCEEDED, (state, action) => {
      state.loading = false;
      state.filters = state.filters.filter(
        (item) => item.siteId !== action.payload?.siteId,
      );
      state.filters.push(action.payload);
      return state;
    })
    .addCase(types.FETCH_FILTER_FAILED, (state) => {
      state.loading = false;
      return state;
    })
    .addCase(types.FILTER_CHANGE_ACCESS_LEVEL, (state) => {
      state.loading = true;
      return state;
    })
    .addCase(types.FILTER_CHANGE_ACCESS_LEVEL_FAILED, (state) => {
      state.loading = false;
      return state;
    });
});
