import { createReducer } from '@reduxjs/toolkit';
import actions from './actions';

const INITIAL_SITE = {
  orgs: [],
};

export default createReducer(INITIAL_SITE, (builder) => {
  builder.addCase(actions.setOrgs, (state, action) => ({
    ...state,
    orgs: action?.payload || [],
  }));
});
