import { createAction } from '@reduxjs/toolkit';
import * as types from './types';

export default {
  fetchDashboard: createAction(types.DASHBOARD_REQUEST_API_GETLIST),
  fetchingDashboard: createAction(
    types.DASHBOARD_UPDATESTATE_GETLIST_FETCHSTATE,
  ),
  changeDashboardAccessLevel: (payload) => ({
    type: types.DASHBOARD_CHANGE_ACCESS_LEVEL,
    payload,
  }),
  changeDashboardAccessLevelFailed: (payload) => ({
    type: types.DASHBOARD_CHANGE_ACCESS_LEVEL_FAILED,
    payload,
  }),
};
