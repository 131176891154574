import { backEndApi } from '@root/libs';

const api = '/api/v1';
const securityPath = '/security';
const securityAPIs = {
  getProjectsInformation: ({ siteIds }) =>
    backEndApi.get(`${api}${securityPath}/projects?siteIds=${siteIds}`),
  renewProjectsData: (body) =>
    backEndApi.post(`${api}${securityPath}/projects/renew`, body),
  changeAccessLevel: (data) =>
    backEndApi.put(`${api}${securityPath}/change-access-level`, data),
  getDashBoardInformation: ({ siteIds }) =>
    backEndApi.get(`${api}${securityPath}/dashboards?siteIds=${siteIds}`),
  renewDashBoardsData: (body) =>
    backEndApi.post(`${api}${securityPath}/dashboards/renew`, body),
  getFilterInformation: ({ siteIds }) =>
    backEndApi.get(`${api}${securityPath}/filters?siteIds=${siteIds}`),
  renewFiltersData: (body) =>
    backEndApi.post(`${api}${securityPath}/filters/renew`, body),
  updateDashboardAccessLevel: (body) =>
    backEndApi.put(`${api}${securityPath}/dashboard/update-access-level`, body),
  updateFilterAccessLevel: (body) =>
    backEndApi.put(`${api}${securityPath}/filter/update-access-level`, body),
};
export default securityAPIs;
