import atlasAPIs from './atlasApis';

export default {
  fetchDashboard: (siteId, startAt, maxResults) =>
    atlasAPIs.proxyApiByApiToken({
      headers: {
        siteid: siteId,
        Accept: 'application/json',
        relayapi:
          `/rest/api/3/dashboard/search?startAt=${startAt}` +
          `&maxResults=${maxResults}&expand=owner,editPermissions,sharePermissions`,
        relaymethod: 'GET',
      },
    }),
};
