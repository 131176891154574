/* eslint-disable import/no-cycle */
import UserPool from '@root/components/AuthPages/UserPool';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';

const cognitoApis = {
  refreshToken: async (code) =>
    // eslint-disable-next-line no-return-await
    new Promise((resolve, reject) => {
      const cognitoUser = UserPool.getCurrentUser();
      if (!cognitoUser) {
        reject(new Error('No user found'));
        return;
      }
      const token = new CognitoRefreshToken({ RefreshToken: code });
      cognitoUser.refreshSession(token, (err, session) => {
        if (err) {
          reject(err);
          return;
        }
        // const { idToken, refreshToken, accessToken } = session
        resolve(session);
      });
    }),
};

export default cognitoApis;
