/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { REQUESTING, REQUESTFAIL } from '../requestState';
import * as types from './types';

const INITIAL_SITE = {
  fetchState: false,
  dashboards: [],
  errorIds: [],
};

export default createReducer(INITIAL_SITE, (builder) => {
  builder
    .addCase(types.DASHBOARD_REQUEST_API_GETLIST, (state) => ({
      ...state,
      fetchState: REQUESTING,
    }))
    .addCase(
      types.DASHBOARD_UPDATESTATE_GETLIST_FETCHSTATE,
      (state, action) => ({
        ...state,
        fetchState: action.payload,
      }),
    )
    .addCase(types.DASHBOARD_UPDATESTATE_GETLISTDATA, (state, action) => ({
      ...state,
      dashboards: action.payload,
    }))
    .addCase(types.DASHBOARD_REQUEST_API_GETLIST_FAILED, (state) => ({
      ...state,
      fetchState: REQUESTFAIL,
    }))
    .addCase(types.DASHBOARD_CHANGE_ACCESS_LEVEL, (state) => ({
      ...state,
      fetchState: REQUESTING,
    }))
    .addCase(types.DASHBOARD_CHANGE_ACCESS_LEVEL_FAILED, (state, action) => ({
      ...state,
      errorIds: action?.payload?.ids || [],
      fetchState: REQUESTFAIL,
    }));
});
