import { createAction } from '@reduxjs/toolkit';
import * as types from './types';

export default {
  fetchFilter: createAction(types.FETCH_FILTER),
  changeFilterAccessLevel: (payload) => ({
    type: types.FILTER_CHANGE_ACCESS_LEVEL,
    payload,
  }),
};
