import * as types from './types';

const INITIAL_STATE = {
  loading: false,
  loadingUser: true,
  currentUser: null,
  accessToken: '',
  snackbar: {
    open: false,
    type: 'info',
    messageKey: '',
    message: '',
  },
  modals: [{ key: null, state: false }],
  renewModalData: '',
};

export default (state = INITIAL_STATE, action) => {
  if (action.type.includes('__FETCHING')) return { ...state, loading: true };
  if (action.type.includes('__FAIL')) return { ...state, loading: false };
  switch (action.type) {
    case types.INITIAL:
      return { ...state };
    case types.SET_USER_TOKEN:
      return {
        ...state,
        accessToken: action.payload,
      };
    case types.OPEN_SNACK_BAR: {
      const { type, messageKey, message } = action.payload;
      const snackbar = messageKey
        ? { open: true, type, messageKey }
        : { open: true, type: action.payload.type, message };
      return { ...state, snackbar };
    }
    case types.CLOSE_SNACK_BAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          open: false,
        },
      };
    case types.GET_CURRENT_USER:
      return { ...state, loadingUser: true };
    case types.SET_CURRENT_USER: {
      const { payload } = action;
      return {
        ...state,
        currentUser: payload,
        loadingUser: false,
      };
    }
    case types.TOGGLE_MODAL: {
      const {
        payload: { key, data },
      } = action;
      const filterKeys =
        key === 'data-transfer-status' || key === 'data-transfer-status-detail'
          ? ['user-bucket-setting']
          : ['data-transfer-status'];

      const modal = state.modals.find((m) => m.key === key);
      const updatedModals = [
        ...state.modals.filter(
          (m) => m.key && m.key !== key && !filterKeys.includes(m.key),
        ),
        { key, state: modal ? !modal.state : true, data, renew: false },
      ];

      return { ...state, modals: updatedModals };
    }
    case types.RENEW_MODAL_DATA: {
      const {
        payload: { key },
      } = action;
      return { ...state, renewModalData: key };
    }
    default:
      return state;
  }
};
