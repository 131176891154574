/* eslint-disable no-unused-vars, no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import { ViewTypeComponent } from '@root/utils/constant';
import { NONREQUEST } from '@root/redux/requestState';
import cloneDeep from 'lodash/cloneDeep';
import actions from './actions';

const INITIAL_SITE = {
  loadingMenu: true,
  isUsersBoardReady: false,
  siteList: [],

  fetchSiteDetailState: NONREQUEST,
  sites: [],
  site: null, // detail
  spacesFetchState: NONREQUEST,
  projectFetchState: NONREQUEST,
  userManager: {
    removal: {
      isConfirmDialogShowing: false,
      user: {},
      siteId: '',
    },
    invitation: {
      user: {},
      siteId: '',
      state: '', // "not_invited", "inviting", "invited", "failed" "declined", "accepted"
    },
  },
};

export default createReducer(INITIAL_SITE, (builder) => {
  builder
    .addCase(actions.setLoading, (state, action) => ({
      ...state,
      isLoading: action.payload,
    }))
    .addCase(actions.setUserBoard, (state, { payload }) => ({
      ...state,
      isUsersBoardReady: payload,
    }))
    .addCase(actions.updateSite, (state, action) => {
      state.siteList = state.siteList.map((site) => {
        if (action.payload.id !== site.siteId) return site;
        const { name, description } = action.payload;
        return {
          ...site,
          name,
          description,
        };
      });
      return state;
    })
    .addCase(actions.setSites, (state, action) => {
      let siteList = [];
      if (Array.isArray(action.payload) && action.payload?.length > 0)
        siteList = action.payload.map((site) => ({
          ...site,
          label: site.name,
          value: site.siteId,
        }));
      return {
        ...state,
        siteList,
      };
    })
    .addCase(actions.setSiteItems, (state, action) => {
      const { values, siteId } = action.payload;
      const siteList = [...state.siteList];
      const siteIndex = siteList.findIndex((s) => s.siteId === siteId);
      siteList[siteIndex] = {
        ...siteList[siteIndex],
        items: values,
      };
      return {
        ...state,
        siteList,
      };
    })
    .addCase(actions.updateSiteItem, (state, action) => {
      const { siteItem = {} } = action.payload;
      const { data, siteId, key } = siteItem;
      const site = state.siteList.find((s) => s.siteId === siteId);
      const item = (site.items || []).find((s) => s.key === key);
      if (item && item.project) {
        item.project.detail = data.project;
      }
      if (item && item.space) {
        item.space.detail = data.space;
      }
      state.siteList.find((s) => s.siteId === siteId).isLoading = false;
      return state;
    })
    .addCase(actions.updateSiteItems, (state, action) => {
      const { siteItems = [] } = action.payload;
      siteItems.forEach((siteItem) => {
        const { data, siteId, key } = siteItem;
        const site = state.siteList.find((s) => s.siteId === siteId);
        const item = (site?.items || []).find((s) => s.key === key);
        if (item && item.project) {
          item.project.detail = data.project;
        }
        if (item && item.space) {
          item.space.detail = data.space;
        }
      });
      return state;
    })
    .addCase(actions.setSiteProjects, (state, action) => {
      const { values, siteId } = action.payload;
      const site = state.siteList.find((s) => s.siteId === siteId);
      site.projects = values;
      return state;
    })
    .addCase(actions.setSiteSpaces, (state, action) => {
      const { values, siteId } = action.payload;
      const site = state.siteList.find((s) => s.siteId === siteId);
      site.spaces = values;
      return state;
    })
    .addCase(actions.setProjectUserAndGroup, (state, action) => {
      const { groupNames, userIds, totalUser, totalGroup, siteId, projectId } =
        action.payload;
      const site = state.siteList.find((s) => s.siteId === siteId);
      const project = (site.projects || []).find((s) => s.id === projectId);
      project.groupNames = groupNames;
      project.userIds = userIds;
      project.totalUser = totalUser;
      project.totalGroup = totalGroup;
      return state;
    })
    .addCase(actions.setSpaceUserAndGroup, (state, action) => {
      const {
        totalUser,
        totalGroup,
        siteId,
        spaceId,
        users,
        groups,
        operations,
      } = action.payload;
      const site = state.siteList.find((s) => s.siteId === siteId);
      const space = (site.spaces || []).find((s) => s.id === spaceId);
      space.totalUser = totalUser;
      space.totalGroup = totalGroup;
      space.users = users;
      space.groups = groups;
      space.operations = operations;
      return state;
    })
    .addCase(actions.loadSiteUsers, (state, action) => {
      const { values, siteId, viewTypeComponent } = action.payload;
      if (viewTypeComponent !== ViewTypeComponent.home) {
        const site = state.siteList.find((s) => s.siteId === siteId);
        site.users = values;
      }
      state.loadingMenu = false;
      return state;
    })
    .addCase(actions.setSiteUsers, (state, action) => {
      const { values, siteId } = action.payload;
      const newSiteList = [...state.siteList];
      const siteIndex = newSiteList.findIndex((s) => s.siteId === siteId);
      newSiteList[siteIndex] = {
        ...newSiteList[siteIndex],
        users: values,
      };
      return {
        ...state,
        siteList: newSiteList,
        loadingMenu: false,
      };
    })
    .addCase(actions.setSiteGroups, (state, action) => {
      const { values, siteId } = action.payload;
      const site = state.siteList.find((s) => s.siteId === siteId);
      site.groups = values;
      return state;
    })
    .addCase(actions.setSitePermission, (state, action) => {
      const { values, siteId } = action.payload;
      const site = state.siteList.find((s) => s.siteId === siteId);
      site.isAdmin = values;
      state.loadingMenu = false;
      return state;
    })
    .addCase(actions.setAtlassianUsers, (state, action) => {
      const { values, siteId } = action.payload;

      const newSiteList = [...state.siteList];
      const siteIndex = newSiteList.findIndex((s) => s.siteId === siteId);
      newSiteList[siteIndex] = {
        ...newSiteList[siteIndex],
        atlassianUsers: values,
      };
      return {
        ...state,
        siteList: newSiteList,
        loadingMenu: false,
      };
    })
    .addCase(actions.updateStateSites, (state, action) =>
      // state.siteList = action.payload;
      ({
        ...state,
        sites: action.payload,
      }),
    )
    .addCase(actions.updateStateLoadingMenu, (state, action) => ({
      ...state,
      loadingMenu: action.payload,
    }))
    .addCase(actions.updateStateSiteDetailItems, (state, action) => {
      const { projects, siteId } = action.payload;
      let site = {};
      const newSiteList = [...state.sites];
      site = newSiteList.find((s) => s.siteId === siteId);
      site = {
        ...site,
        projects,
      };
      return {
        ...state,
        site,
      };
    })
    .addCase(actions.updateStateSiteDetailSpaces, (state, action) => {
      const { spaces, siteId } = action.payload;
      let site = {};
      const newSiteList = [...state.sites];
      site = newSiteList.find((s) => s.siteId === siteId);
      site = {
        ...site,
        ...state.site,
        spaces,
      };
      return {
        ...state,
        site,
      };
    })
    .addCase(actions.updateStateSiteDetailSpacesFetchState, (state, action) => {
      const { fetchState } = action.payload;
      return {
        ...state,
        spacesFetchState: fetchState,
      };
    })
    .addCase(actions.updateStateSiteDetailProjects, (state, action) => {
      const { projects, siteId } = action.payload;
      let site = {};
      const newSiteList = [...state.sites];
      site = newSiteList.find((s) => s.siteId === siteId);
      site = {
        ...site,
        ...state.site,
        projects,
      };
      return {
        ...state,
        site,
      };
    })
    // update detail data for each projects, each spaces in site
    .addCase(actions.updateStateSiteItemDetailData, (state, action) => {
      const { detailData, projectKey, siteId } = action.payload;
      const projects = cloneDeep(state.site.projects);
      const spaces = cloneDeep(state.site.spaces);
      // let spaceItemDataLoading = { ...state.spacesFetchState };
      const projectItem = (projects || []).find((s) => s.key === projectKey);
      const spaceItem = (spaces || []).find((s) => s.key === projectKey);
      if (projectItem && detailData.project) {
        projectItem.detail = detailData.project;
        projectItem.isLoading = false;
      }
      if (spaceItem && detailData.space) {
        spaceItem.detail = detailData.space;
        spaceItem.isLoading = false;
      }

      return {
        ...state,
        site: {
          ...state.site,
          spaces,
          projects,
        },
      };
    })
    .addCase(actions.updateStateSiteDetailFetchState, (state, action) => ({
      ...state,
      fetchSiteDetailState: action.payload,
    }))
    .addCase(actions.getDetailItemDataForSpaces, (state, action) => ({
      ...state,
    }))
    .addCase(actions.getDetailItemDataForProject, (state, action) => ({
      ...state,
    }))
    .addCase(actions.updateDetailItemDataForProject, (state, action) => ({
      ...state,
    }))
    .addCase(actions.updateOrganizationIdForSiteListState, (state, action) => {
      const { sites, orgId, organizationName } = action.payload;
      let siteList = cloneDeep(state.siteList);
      siteList = siteList.map((site) => {
        if (sites.includes(site.url))
          return {
            ...site,
            organization_id: orgId,
            organizationName,
          };
        return site;
      });
      return {
        ...state,
        siteList,
      };
    });
});
