/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';
import actions from './actions';

const INITIAL_SITE = {
  loadingPage: {},
};

export default createReducer(INITIAL_SITE, (builder) => {
  builder.addCase(actions.setPageLoading, (state, action) => {
    const { loadingKey, value } = action.payload;
    state.loadingPage[loadingKey] = value;
    return state;
  });
});
