import { backEndApi } from '@root/libs';

const api = '/api/v1';
const userPath = '/my-account';
const userAPIs = {
  // signup: (body) => backEndApi.post(`${api}${userPath}`, body),
  getMe: () => backEndApi.get(`${api}${userPath}`),
  updateLastLoginDate: (body) =>
    backEndApi.put(`${api}${userPath}/last-login-date`, body),
};
export default userAPIs;
