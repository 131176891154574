const prefix = 'SITE/';

export const INITIAL = `${prefix}INITIAL`;
export const FETCH_INITIAL_DATA = `${prefix}FETCH_INITIAL_DATA`;
export const SITE_PROCESSING = `${prefix}SITE_PROCESSING`;
export const USER_BOARD_STATE = `${prefix}USER_BOARD_STATE`;
export const GET_SITE_LIST = `${prefix}GET_SITE_LIST`;
export const GET_SITE_SAGA = `${prefix}GET_SITE_SAGA`;
export const GET_SITES_SAGA = `${prefix}GET_SITES_SAGA`;
export const CREATE_SITE_SAGA = `${prefix}CREATE_SITE_SAGA`;
export const DELETE_SITE_SAGA = `${prefix}DELETE_SITE_SAGA`;
export const UPDATE_SITE_SAGA = `${prefix}UPDATE_SITE_SAGA`;
export const UPDATE_SITE = `${prefix}UPDATE_SITE`;
export const CREATE_SITE = `${prefix}CREATE_SITE`;
export const PROJECT_PROCESSING = `${prefix}PROJECT_PROCESSING`;
export const SPACE_PROCESSING = `${prefix}SPACE_PROCESSING`;
export const FETCH_CHILD_SITE = `${prefix}FETCH_CHILD_SITE`;
export const FETCH_SPACES_BY_SITE_SAGA = `${prefix}FETCH_SPACES_BY_SITE_SAGA`;
export const SET_SITE_PROJECTS = `${prefix}SET_SITE_PROJECTS`;
export const SET_SITE_SPACES = `${prefix}SET_SITE_SPACES`;
export const SET_PROJECT_USER = `${prefix}SET_PROJECT_USER`;
export const SET_PROJECT_GROUP_AND_USER = `${prefix}SET_PROJECT_GROUP_AND_USER`;
export const SET_SPACE_GROUP_AND_USER = `${prefix}SET_SPACE_GROUP_AND_USER`;
export const LOAD_SITE_USERS = `${prefix}LOAD_SITE_USERS`;
export const SET_SITE_USERS = `${prefix}SET_SITE_USERS`;
export const SET_SITE_GROUPS = `${prefix}SET_SITE_GROUPS`;
export const SET_SITE_ITEM = `${prefix}SET_SITE_ITEM`;
export const UPDATE_SITE_ITEM = `${prefix}UPDATE_SITE_ITEM`;
export const UPDATE_SITE_ITEMS = `${prefix}UPDATE_SITE_ITEMS`;
export const SET_SITE_PERMISSION = `${prefix}SET_SITE_PERMISSION`;
export const SET_ATLASSIAN_USERS = `${prefix}SET_ATLASSIAN_USERS`;

export const SITE_UPDATESTATE_LOADING_MENU = `${prefix}UPDATESTATE_LOADING_MENU`;
export const SITE_REQUESTAPI_GETLIST = `${prefix}REQUESTAPI_GETLIST`;
export const SITE_UPDATESTATE_GETLIST = `${prefix}UPDATESTATE_GETLIST`;
export const SITE_REQUESTAPI_GET_SITE_DETAIL_ITEMS = `${prefix}GET_SITE_DETAIL_ITEMS`;
export const SITE_REQUESTAPI_GET_ALL_PROJECT_SITE = `${prefix}GET_ALL_PROJECT_SITE`;

export const SITE_UPDATESTATE_GET_SITE_DETAIL_ITEMS = `${prefix}UPDATESTATE_GET_SITE_DETAIL_ITEMS`;
export const SITE_UPDATESTATE_SITE_DETAIL_ITEM_DATA = `${prefix}UPDATESTATE_SITE_DETAIL_ITEM_DATA`;
export const SITE_UPDATESTATE_GET_SITE_DETAIL_FETCHSTATE = `${prefix}UPDATESTATE_GET_SITE_DETAIL_FETCHSTATE`;
export const SITE_UPDATESTATE_GET_SITE_DETAIL_SPACES = `${prefix}UPDATESTATE_GET_SITE_DETAIL_SPACES`;
export const SITE_UPDATESTATE_GET_SITE_DETAIL_SPACES_FETCHSTATE = `${prefix}UPDATESTATE_GET_SITE_DETAIL_SPACES_FETCHSTATE`;
export const SITE_UPDATESTATE_GET_SITE_DETAIL_PROJECTS = `${prefix}UPDATESTATE_GET_SITE_DETAIL_PROJECTS`;
export const SITE_UPDATESTATE_GET_SITE_DETAIL_PROJECTS_FETCHSTATE = `${prefix}UPDATESTATE_GET_SITE_DETAIL_PROJECTS_FETCHSTATE`;
export const SITE_REQUESTAPI_GET_DETAIL_ITEM_DATA_FOR_SPACES = `${prefix}REQUESTAPI_GET_DETAIL_ITEM_DATA_FOR_SPACES`;
export const SITE_REQUESTAPI_GET_DETAIL_ITEM_DATA_FOR_PROJECT = `${prefix}REQUESTAPI_GET_DETAIL_ITEM_DATA_FOR_PROJECT`;
export const SITE_UPDATE_GET_DETAIL_ITEM_DATA_FOR_PROJECT = `${prefix}UPDATE_GET_DETAIL_ITEM_DATA_FOR_PROJECT`;
export const SITE_REQUESTAPI_GET_DETAIL_DATA_SPACES = `${prefix}SITE_REQUESTAPI_GET_DETAIL_DATA_SPACES`;
export const SITE_UPDATESTATE_ORGANIZATION = `${prefix}SITE_UPDATESTATE_ORGANIZATION`;
