import {
  configureStore as configureStoreToolkit,
  createImmutableStateInvariantMiddleware,
} from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';
import rootSaga from './saga';

const configureStore = (initialState = {}) => {
  const appReducer = createReducer();
  const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
      return appReducer(undefined, action);
    }
    return appReducer(state, action);
  };
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware];
  if (process.env.NODE_ENV === 'development') {
    const loggerMiddleware = createLogger({ collapsed: true });
    middlewares.push(loggerMiddleware);
  }
  const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
    ignoredPaths: ['ignoredPath', 'ignoredNested.one', 'ignoredNested.two'],
  });

  middlewares.push(immutableInvariantMiddleware);
  const store = configureStoreToolkit({
    reducer: rootReducer,
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: false,
        immutableCheck: true,
      }).concat(middlewares),
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export default configureStore;
