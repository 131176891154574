/* eslint-disable no-unused-vars */
import { takeEvery, call, put } from 'redux-saga/effects';
import { isNotEmptyArray } from '@root/utils';
import * as types from './types';
import filterApis from '../../services/filterApis';
import securityAPIs from '../../services/securityApis';

const parseFilterData = (filterData) => {
  if (isNotEmptyArray(filterData?.value)) {
    const newValue = filterData.value
      .sort((item1, item2) =>
        item1.sharePermissions?.[0]?.type === 'global' ? -1 : 0,
      )
      .map((filter) => {
        const {
          name,
          owner,
          editPermissions = [],
          sharePermissions = [],
          id,
        } = filter;
        let permissions = editPermissions.concat(sharePermissions);
        permissions = permissions.map((p) => ({
          ...p,
          type: p.type === 'project-unknown' ? 'private' : p.type,
        }));
        const viewers =
          permissions.length > 0
            ? permissions.map((viewer) => ({
                ...viewer,
                key: viewer?.[viewer.type]?.key || null,
              }))
            : [{ type: 'private' }];
        return { name, owner, viewers, id };
      });
    return { ...filterData, value: newValue };
  }
  return { ...filterData, value: [] };
};

// eslint-disable-next-line import/prefer-default-export
export function* fetchFilterSaga() {
  yield takeEvery(
    types.FETCH_FILTER,
    function* fetchFilter({ payload: siteId }) {
      let filterData;
      let isCompleted = false;
      let startAt = 0;
      const maxResults = 100;
      yield put({
        type: types.FETCHING_FILTER,
        payload: { siteId, isFetching: true },
      });
      let filters = [];
      while (!isCompleted) {
        const response = yield call(
          filterApis.fetchFilter,
          siteId,
          startAt,
          maxResults,
        );
        const { status } = response;
        if (status === 200) {
          const {
            data: { isLast, values },
          } = response;
          filters = filters.concat(values);
          filterData = { value: filters, siteId };
          isCompleted = isLast;
          startAt += maxResults;
        } else {
          isCompleted = true;
          yield put({ type: types.FETCH_FILTER_FAILED });
        }
      }
      yield put({
        type: types.FETCHING_FILTER,
        payload: { siteId, isFetching: false },
      });
      yield put({
        type: types.FETCH_FILTER_SUCCEEDED,
        payload: parseFilterData(filterData),
      });
    },
  );
}

export function* changeFilterAccessLevelSaga() {
  yield takeEvery(
    types.FILTER_CHANGE_ACCESS_LEVEL,
    function* changeFilterAccessLevel({ payload }) {
      const response = yield call(securityAPIs.changeAccessLevel, payload);
      const { status } = response;
      if (status === 200) {
        yield put({ type: types.FETCH_FILTER, payload: payload.siteId });
      } else {
        yield put({ type: types.FILTER_CHANGE_ACCESS_LEVEL_FAILED });
      }
    },
  );
}
