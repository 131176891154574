import { createAction } from '@reduxjs/toolkit';
import * as types from './types';

export default {
  // general actions
  setLoading: createAction(types.SITE_PROCESSING),
  setUserBoard: createAction(types.USER_BOARD_STATE),
  setSites: createAction(types.GET_SITE_LIST),
  setSiteUsers: createAction(types.SET_SITE_USERS),
  loadSiteUsers: createAction(types.LOAD_SITE_USERS),
  setSiteGroups: createAction(types.SET_SITE_GROUPS),
  setSiteProjects: createAction(types.SET_SITE_PROJECTS),
  setSiteSpaces: createAction(types.SET_SITE_SPACES),
  setSiteItems: createAction(types.SET_SITE_ITEM),
  updateSiteItem: createAction(types.UPDATE_SITE_ITEM),
  updateSiteItems: createAction(types.UPDATE_SITE_ITEMS),
  updateSite: createAction(types.UPDATE_SITE),
  setProjectUserAndGroup: createAction(types.SET_PROJECT_GROUP_AND_USER),
  setSpaceUserAndGroup: createAction(types.SET_SPACE_GROUP_AND_USER),
  setSitePermission: createAction(types.SET_SITE_PERMISSION),
  setAtlassianUsers: createAction(types.SET_ATLASSIAN_USERS),

  fetchChildSite: (payload) => ({ type: types.FETCH_CHILD_SITE, payload }),
  createSite: (payload) => ({ type: types.CREATE_SITE_SAGA, payload }),
  deleteSite: (payload) => ({ type: types.DELETE_SITE_SAGA, payload }),
  getSites: (payload) => ({ type: types.GET_SITES_SAGA, payload }),
  getSite: (payload) => ({ type: types.GET_SITE_SAGA, payload }),
  updateSiteSaga: (payload) => ({ type: types.UPDATE_SITE_SAGA, payload }),

  updateStateLoadingMenu: createAction(types.SITE_UPDATESTATE_LOADING_MENU),
  getSiteList: (payload) => ({ type: types.SITE_REQUESTAPI_GETLIST, payload }),
  updateStateSites: createAction(types.SITE_UPDATESTATE_GETLIST),
  updateStateSiteDetailItems: createAction(
    types.SITE_UPDATESTATE_GET_SITE_DETAIL_ITEMS,
  ),
  updateStateSiteItemDetailData: createAction(
    types.SITE_UPDATESTATE_SITE_DETAIL_ITEM_DATA,
  ),
  updateStateSiteDetailFetchState: createAction(
    types.SITE_UPDATESTATE_GET_SITE_DETAIL_FETCHSTATE,
  ),
  getSiteDetailprojectsAndSpacesBySiteId: createAction(
    types.SITE_REQUESTAPI_GET_SITE_DETAIL_ITEMS,
  ),
  updateStateSiteDetailSpaces: createAction(
    types.SITE_UPDATESTATE_GET_SITE_DETAIL_SPACES,
  ),
  updateStateSiteDetailSpacesFetchState: createAction(
    types.SITE_UPDATESTATE_GET_SITE_DETAIL_SPACES_FETCHSTATE,
  ),
  updateStateSiteDetailProjects: createAction(
    types.SITE_UPDATESTATE_GET_SITE_DETAIL_PROJECTS,
  ),
  getDetailItemDataForSpaces: createAction(
    types.SITE_REQUESTAPI_GET_DETAIL_ITEM_DATA_FOR_SPACES,
  ),
  getDetailItemDataForProject: createAction(
    types.SITE_REQUESTAPI_GET_DETAIL_ITEM_DATA_FOR_PROJECT,
  ),
  updateDetailItemDataForProject: createAction(
    types.SITE_UPDATE_GET_DETAIL_ITEM_DATA_FOR_PROJECT,
  ),
  getDetailDataSpace: createAction(
    types.SITE_REQUESTAPI_GET_DETAIL_DATA_SPACES,
  ),
  // used to update siteList state after update organization API key
  // display organization name in site select without reload
  updateOrganizationIdForSiteListState: createAction(
    types.SITE_UPDATESTATE_ORGANIZATION,
  ),
};
