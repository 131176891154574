import { call, put, takeLatest } from 'redux-saga/effects';
import { userAPIs } from '../../services';
import actions from './actions';
import * as types from './types';

export function* getCurrentUserSaga() {
  yield takeLatest(types.GET_CURRENT_USER, function* getCurrentUser() {
    try {
      // TODO: update db user with cognito data
      const response = yield call(userAPIs.getMe);
      const { data: user } = response.data;
      const { id, email, atlassianAccountId } = user;
      const currentUser = {
        id,
        email,
        atlassianAccountId,
      };
      yield put(actions.setCurrentUser(currentUser));
    } catch (getCurrentUserError) {
      console.groupCollapsed('Get current user error');
      console.log(getCurrentUserError);
      console.groupEnd();
      yield put(actions.setCurrentUser(undefined));
    }
  });
}
export default getCurrentUserSaga;
