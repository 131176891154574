/* eslint-disable no-unused-vars */
import { takeEvery, call, put, all, takeLatest } from 'redux-saga/effects';
import { isNotEmptyArray } from '@root/utils';
import { ALERT_TYPE } from '@root/components/common/MessageSnackbar';
import * as types from './types';
import appActions from '../app/actions';
import dashboardApis from '../../services/dashboardApis';
import securityAPIs from '../../services/securityApis';
import { REQUESTFAIL, REQUESTING, REQUESTSUCCESS } from '../requestState';
import { OPEN_SNACK_BAR } from '../app/types';

const parseDashboardData = (dashboardData) => {
  if (isNotEmptyArray(dashboardData?.value)) {
    const newValue = dashboardData.value
      .sort((item1, item2) =>
        item1.sharePermissions?.[0]?.type === 'global' ? -1 : 0,
      )
      .map((dashboard) => {
        const {
          name,
          owner,
          editPermissions = [],
          sharePermissions = [],
          siteId,
          id,
        } = dashboard;
        const permissions = editPermissions.concat(sharePermissions);
        const newViewers =
          permissions.length > 0
            ? permissions.map((viewer) => ({
                ...viewer,
                key: viewer?.[viewer.type]?.key || null,
              }))
            : [{ type: 'private' }];
        return { name, owner, viewers: newViewers, id };
      });
    return { ...dashboardData, value: newValue };
  }
  return { ...dashboardData, value: [] };
};

function* getDashboards({ payload: siteId }) {
  let dashboardData;
  let isCompleted = false;
  let startAt = 0;
  const maxResults = 100;
  let dashboardList = [];
  while (!isCompleted) {
    const response = yield call(
      dashboardApis.fetchDashboard,
      siteId,
      startAt,
      maxResults,
    );
    const { status } = response;
    if (status === 200) {
      const {
        data: { isLast, values },
      } = response;
      const filteredValues = values.filter((item) => item.id !== '10000');
      dashboardList = dashboardList.concat(filteredValues);
      dashboardData = { value: dashboardList, siteId };
      isCompleted = isLast;
      if (isLast) {
        yield put({
          type: types.DASHBOARD_UPDATESTATE_GETLIST_FETCHSTATE,
          payload: REQUESTSUCCESS,
        });
      }
      startAt += maxResults;
    } else {
      isCompleted = true;
      yield put({
        type: types.DASHBOARD_UPDATESTATE_GETLIST_FETCHSTATE,
        payload: REQUESTFAIL,
      });
    }
  }
  const dashboards = parseDashboardData(dashboardData);

  yield put({
    type: types.DASHBOARD_UPDATESTATE_GETLISTDATA,
    payload: dashboards?.value || [],
  });
}
export function* dashboardSaga() {
  yield all([takeLatest(types.DASHBOARD_REQUEST_API_GETLIST, getDashboards)]);
}
export function* changeDashboardAccessLevelSaga() {
  yield takeEvery(
    types.DASHBOARD_CHANGE_ACCESS_LEVEL,
    function* changeDashboardAccessLevel({ payload }) {
      const response = yield call(securityAPIs.changeAccessLevel, payload);
      const { status } = response;
      if (status === 200) {
        yield put({
          type: types.DASHBOARD_REQUEST_API_GETLIST,
          payload: payload.siteId,
        });
      } else {
        yield put({
          type: types.DASHBOARD_CHANGE_ACCESS_LEVEL_FAILED,
          payload: {
            ids: response?.data?.ids,
          },
        });
      }
    },
  );
}
