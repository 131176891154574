import { put, takeLatest } from 'redux-saga/effects';
import * as types from './types';

export function* fetchSpacePagesSaga() {
  try {
    yield put({
      type: types.SPACE_PROCESSING,
      payload: { loadingKey: 'pageListLoading', value: true },
    });
  } catch (error) {
    yield put({
      type: types.SPACE_PROCESSING,
      payload: { loadingKey: 'pageListLoading', value: false },
    });
    console.log('----fetchSpacePagesSaga error----', error);
  }
}

export function* resetLoadingSaga() {
  yield takeLatest(types.RESET_LOADING, function* resetLoading() {
    try {
      yield put({
        type: types.SPACE_PROCESSING,
        payload: { loadingKey: 'pageListLoading', value: false },
      });
    } catch (error) {
      yield put({ type: types.SPACE_PROCESSING, payload: true });
      console.log('----resetLoadingSaga error----', error);
    }
  });
}

export function* setLoadingSaga() {
  yield takeLatest(types.SET_LOADING, function* setLoading() {
    try {
      yield put({
        type: types.SPACE_PROCESSING,
        payload: { loadingKey: 'pageListLoading', value: true },
      });
    } catch (error) {
      yield put({ type: types.SPACE_PROCESSING, payload: false });
      console.log('----setLoadingSaga error----', error);
    }
  });
}
