import * as types from './types';

const INITIAL_STATE = {
  urlParams: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SET_URL_PARAMS:
      return {
        ...state,
        urlParams: action.payload,
      };
    default:
      return state;
  }
};
