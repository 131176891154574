const prefix = 'APP/';

export const INITIAL = `${prefix}INITIAL`;
export const SET_USER_TOKEN = `${prefix}SET_USER_TOKEN`;
export const GET_USER_SAGA = `${prefix}GET_USER_SAGA`;
export const GET_USER_INFO = `${prefix}GET_USER_INFO`;
export const GET_CURRENT_USER = `${prefix}GET_CURRENT_USER`;
export const SET_CURRENT_USER = `${prefix}SET_CURRENT_USER`;
export const OPEN_SNACK_BAR = `${prefix}OPEN_SNACK_BAR`;
export const CLOSE_SNACK_BAR = `${prefix}CLOSE_SNACK_BAR`;
export const TOGGLE_MODAL = `${prefix}TOGGLE_MODAL`;
export const RENEW_MODAL_DATA = `${prefix}RENEW_MODAL_DATA`;
export const RENEW_AUDIT_LOG_DATA = `${prefix}RENEW_AUDIT_LOG_DATA`;
