import { createAction } from '@reduxjs/toolkit';
import * as types from './types';

export default {
  getUser: (payload) => ({ type: types.GET_USER_SAGA, payload }),
  getCurrentUser: createAction(types.GET_CURRENT_USER),
  setCurrentUser: createAction(types.SET_CURRENT_USER),
  setToken: (payload) => ({ type: types.SET_USER_TOKEN, payload }),
  openSnackbar: (payload) => ({ type: types.OPEN_SNACK_BAR, payload }),
  closeSnackbar: () => ({ type: types.CLOSE_SNACK_BAR }),
  toggleModal: createAction(types.TOGGLE_MODAL),
  renewModalData: createAction(types.RENEW_MODAL_DATA),
};
